import React, { useEffect, useRef } from "react";
import $ from "jquery";
import "daterangepicker/daterangepicker.css";
import "daterangepicker";
import moment from "moment";

const DateRangePicker = ({
  name = "daterange",
  startDate,
  endDate,
  onDateChange,
}) => {
  const dateRangePickerRef = useRef(null);

  useEffect(() => {
    const formattedStartDate = startDate
      ? startDate.format("MM/DD/YYYY")
      : moment().format("MM/DD/YYYY");
    const formattedEndDate = endDate
      ? endDate.format("MM/DD/YYYY")
      : moment().format("MM/DD/YYYY");

    // Initialize the date range picker
    $(dateRangePickerRef.current).daterangepicker(
      {
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      },
      (start, end, label) => {
        console.log(
          "A new date selection was made: " +
            start.format("YYYY-MM-DD") +
            " to " +
            end.format("YYYY-MM-DD")
        );
        if (onDateChange) {
          onDateChange(start, end);
        }
      }
    );

    // Apply the custom class to move the calendar to the right
    $(dateRangePickerRef.current).on(
      "show.daterangepicker",
      function (event, picker) {
        picker.container.addClass("custom-daterangepicker");
      }
    );
  }, [name, onDateChange, startDate, endDate]);

  return (
    <input
      type="text"
      className="form-control"
      name={name}
      ref={dateRangePickerRef}
    />
  );
};

export default DateRangePicker;
