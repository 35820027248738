import React, { useState } from "react";
import { FaHome, FaUser } from "react-icons/fa";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./sidebarstyle.css";

const Sidebar = ({ onLinkClick, activeView }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="sidebar open">
      <div className="sidebar-header">
        <div className="text-center">
          <img
            src="https://ecom.leopardscourier.com/build/images/logo/LCS-logo1.png"
            alt="Leopard Couriers"
            className="logo"
            width={"65%"}
            // style={{ width: "65%" }}
          />
        </div>
      </div>
      <div style={{ minHeight: "19px" }} />
      <div className="sidebar-menu">
        <h3>General</h3>
        {/* <div className="sidebar-section">
          <hr className="sidebar-divider" />
          <span className="section-header">GENERAL</span>
          <hr className="sidebar-divider" />
        </div> */}
        <a
          href="#dashboard"
          className="sidebar-link dashboard"
          onClick={() => onLinkClick("dashboard")}
        >
          <FaHome className="icon" />
          <span className="link-text">Dashboard</span>
        </a>
        <a
          href="#manage-shipper"
          className="sidebar-link manage-shipper"
          onClick={() => onLinkClick("manage-shipper")}
        >
          <FaUser className="icon" />
          <span className="link-text">Manage Shipper</span>
          <i
            style={{ fontSize: "10px", marginLeft: "33px", color: "#444444" }}
            className="fa fa-chevron-down"
          ></i>
        </a>
        <a
          href="#manage-booking"
          className="sidebar-link manage-booking"
          onClick={() => onLinkClick("manage-booking")}
        >
          <i
            className="fa fa-book"
            style={{
              marginRight: "13px",
              fontSize: "17px",
              color: "  #ffcc05",
            }}
          ></i>
          <span className="link-text">Manage Booking</span>
          <i
            style={{ marginLeft: "33px", color: "#444444", fontSize: "10px" }}
            className="fa fa-chevron-down"
          ></i>
        </a>
        <div className="sidebar-dropdown report-dropdown">
          <button
            className={`dropdown-btn ${isDropdownOpen ? "active" : ""}`}
            onClick={toggleDropdown}
          >
            <i
              style={{
                marginRight: "13px",
                fontSize: "15px",
                color: "  #ffcc05",
              }}
              className="fa fa-table"
            ></i>

            <span className="link-text">Report Manager</span>
            <i
              style={{ color: "#444444", fontSize: "10px" }}
              className="fa fa-chevron-down"
            ></i>
          </button>
          {isDropdownOpen && (
            <div className="dropdown-container report-dropdown-container">
              <a
                href="#cancel-shipments"
                className="sidebar-link special-link"
                onClick={() => onLinkClick("cancel-shipments")}
              >
                <span
                  className="link-text"
                  style={{ marginLeft: "22px", fontSize: "13px" }}
                >
                  Cancel Shipments
                </span>
              </a>
              <a
                href="#return-shipments"
                className="sidebar-link special-link"
                onClick={() => onLinkClick("return-shipments")}
              >
                <span
                  className="link-text"
                  style={{ marginLeft: "22px", fontSize: "13px" }}
                >
                  Return Shipments
                </span>
              </a>
              <a
                href="#received-payments"
                className="sidebar-link special-link"
                onClick={() => onLinkClick("received-payments")}
              >
                <span
                  className="link-text "
                  style={{ marginLeft: "22px", fontSize: "13px" }}
                >
                  Received Payments
                </span>
              </a>
              <a
                href="#invoice-report"
                className="sidebar-link special-link"
                onClick={() => onLinkClick("invoice-report")}
              >
                <span
                  className="link-text"
                  style={{ marginLeft: "22px", fontSize: "13px" }}
                >
                  Invoice Report
                </span>
              </a>
              <a
                href="#summary-report"
                className={`sidebar-link special-link ${
                  activeView === "summary-report" ? "current-page" : ""
                }`}
                onClick={() => onLinkClick("summary-report")}
              >
                <span
                  className="link-text"
                  style={{ marginLeft: "22px", fontSize: "13px" }}
                >
                  Summary Report
                </span>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
