import React, { useState } from "react";
import { FaBars, FaCaretDown } from "react-icons/fa";
import Sidebar from "./components/Sidebar";
import DateRangePicker from "./components/DateRangePicker";
import "./App.css";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import "./style.css";
import { formatReplace } from "./utils/formatReplace";
import { Helmet } from "react-helmet";

function App() {
  const [activeView, setActiveView] = useState("");

  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());

  console.log(activeView, "check?");
  const [generated, setGenerated] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [grandTotal, setGrandTotal] = useState();
  const [loading, setLoading] = useState(false);

  const handleLinkClick = (view) => {
    setActiveView(view);
    setGenerated(false);
  };

  const handleGenerateClick = async () => {
    setLoading(true);

    Swal.fire({
      title: "Auto closed when data is generated!",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        Swal.hideLoading();
      },
    });

    try {
      await getReports();

      Swal.fire({
        title: "Saved!",
        text: "Form has been submitted successfully",
        icon: "success",
        customClass: {
          popup: "saved-popup",
          title: "saved-title",
          icon: "saved-icon",
          actions: "action",
        },
      });
    } catch (error) {
      Swal.close();
      console.error("Error generating report:", error);
      Swal.fire({
        title: "Error!",
        text: "There was an error generating the report",
        icon: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  const getReports = async () => {
    try {
      const response = await axios.get(
        `/report-summary?start=${startDate.format(
          "YYYY-MM-DD"
        )}&end=${endDate.format("YYYY-MM-DD")}`
      );
      setReportData(response?.data?.summaryRows);
      setGrandTotal(response?.data?.grandTotal);
    } catch (error) {
      console.error("Error fetching report data:", error);
    }
  };

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div className="App">
      <Helmet>
        <title>ECOM Merchant |</title>
      </Helmet>

      <Sidebar onLinkClick={handleLinkClick} activeView={activeView} />
      <div className="App-wrapper">
        <div className="main-content">
          <header className="main-header">
            <div className="header-content">
              <div className="header-title">
                <FaBars className="header-icon" />
                <h1>
                  <span className="bold-text">E-Com</span>{" "}
                  <span className="normal-text">Portal</span>
                </h1>
              </div>
              <div className="user-info">
                <img
                  src="https://ecom.leopardscourier.com/images/img.jpg"
                  className="profile-icon"
                  alt="profile img"
                />
                <span style={{ marginRight: "4px" }}>ESSENTIALS</span>
                {/* <FaCaretDown className="dropdown-icon" /> */}
              </div>
            </div>
          </header>
          {activeView === "summary-report" && (
            <div className="content">
              <div>
                <h3 className="report-manager">Report Manager</h3>

                <div className="summary-report">
                  <div class="x_title">
                    <h2> Summary Report </h2>
                    <ul class="nav navbar-right panel_toolbox justify-content-end">
                      <li>
                        <a class="collapse-link">
                          <i class="fa fa-chevron-up"></i>
                        </a>
                      </li>
                    </ul>
                    <div class="clearfix"></div>
                    <hr />
                    <div class="row">
                      <div class="col-md-4 col-sm-4 col-xs-12">
                        <div class="form-group">
                          <label>Date</label>
                          <div class="input-prepend input-group">
                            <span class="add-on input-group-addon">
                              {/* <img src="./calendar.png" alt="" /> */}
                              {/* <i class="fa fa-calendar"></i> */}
                              <i class="fa">&#xf073;</i>
                            </span>
                            <DateRangePicker
                              onDateChange={handleDateChange}
                              startDate={startDate}
                              endDate={endDate}
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4 col-sm-4 col-xs-12">
                        <div class="form-group">
                          <label>Origin City</label>
                          <div className="input-with-icon">
                            <input
                              type="text"
                              class="form-control"
                              id="exampleInputPassword1"
                              placeholder="Search Origin City"
                            />
                            <FaCaretDown className="input-dropdown-icon" />
                          </div>
                        </div>
                      </div>

                      <div class="col-md-4 col-sm-4 col-xs-12">
                        <div class="form-group">
                          <label>Destination City</label>

                          <div className="input-with-icon">
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Search Destination City"
                            />
                            <FaCaretDown className="input-dropdown-icon" />
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-md-4 col-sm-4 col-xs-12"
                        style={{ paddingTop: "20px" }}
                      >
                        <button
                          onClick={handleGenerateClick}
                          class="btn btn-primary btn-sm"
                        >
                          Generate
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="x_content" style={{ display: "block" }}>
                    <div class="col-md-12" id="summary-report-data">
                      {reportData?.length > 0 && (
                        <>
                          <h3>Summary Report</h3>
                          <table
                            class="table table-striped table-bordered"
                            id="summaryDatatable"
                          >
                            <thead>
                              <tr>
                                <th>SR#</th>
                                <th>Client</th>
                                <th colspan="3" class="text-center">
                                  Delivered
                                </th>
                                <th colspan="3" class="text-center">
                                  Being Return
                                </th>
                                <th colspan="3" class="text-center">
                                  Return To Shipper
                                </th>
                                <th colspan="3" class="text-center">
                                  Under Process
                                </th>
                                <th colspan="3" class="text-center">
                                  Total
                                </th>
                              </tr>
                              <tr>
                                <th></th>
                                <th></th>
                                <th>Packets</th>
                                <th>Cod Amount</th>
                                <th>Inv Charges</th>
                                <th>Packets</th>
                                <th>Cod Amount</th>
                                <th>Inv Charges</th>
                                <th>Packets</th>
                                <th>Cod Amount</th>
                                <th>Inv Charges</th>
                                <th>Packets</th>
                                <th>Cod Amount</th>
                                <th>Inv Charges</th>
                                <th>Packets</th>
                                <th>Cod Amount</th>
                                <th>Inv Charges</th>
                              </tr>
                            </thead>
                            <tbody>
                              {reportData?.map((row, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>LEOPARDS COURIER SERVICE (RETAIL COD)</td>
                                  <td
                                    style={{
                                      fontSize: "13px",
                                      textAlign: "inherit",
                                    }}
                                  >
                                    {row.deliveredOrders.packets?.toLocaleString()}
                                  </td>
                                  <td style={{ fontSize: "13px" }}>
                                    {row.deliveredOrders.codAmount
                                      ? row.deliveredOrders.codAmount
                                          ?.toFixed(2)
                                          ?.replace(formatReplace, ",")
                                      : 0}
                                  </td>
                                  <td style={{ fontSize: "13px" }}>
                                    {row.deliveredOrders.invCharges
                                      ? row.deliveredOrders.invCharges
                                          ?.toFixed(2)
                                          ?.replace(formatReplace, ",")
                                      : 0}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "13px",
                                      textAlign: "inherit",
                                    }}
                                  >
                                    {row.beingReturnOrders.packets?.toLocaleString()}
                                  </td>
                                  <td style={{ fontSize: "13px" }}>
                                    {row.beingReturnOrders.codAmount
                                      ? row.beingReturnOrders.codAmount
                                          ?.toFixed(2)
                                          ?.replace(formatReplace, ",")
                                      : 0}
                                  </td>
                                  <td style={{ fontSize: "13px" }}>
                                    {row.beingReturnOrders.invCharges
                                      ? row.beingReturnOrders.invCharges
                                          ?.toFixed(2)
                                          ?.replace(formatReplace, ",")
                                      : 0}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "13px",
                                      textAlign: "inherit",
                                    }}
                                  >
                                    {row.returnToShipperOrders.packets?.toLocaleString()}
                                  </td>
                                  <td style={{ fontSize: "13px" }}>
                                    {row.returnToShipperOrders.codAmount
                                      ? row.returnToShipperOrders.codAmount
                                          ?.toFixed(2)
                                          ?.replace(formatReplace, ",")
                                      : 0}
                                  </td>
                                  <td style={{ fontSize: "13px" }}>
                                    {row.returnToShipperOrders.invCharges
                                      ? row.returnToShipperOrders.invCharges
                                          ?.toFixed(2)
                                          ?.replace(formatReplace, ",")
                                      : 0}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "13px",
                                      textAlign: "inherit",
                                    }}
                                  >
                                    {row.underProcessOrders.packets?.toLocaleString()}
                                  </td>
                                  <td style={{ fontSize: "13px" }}>
                                    {row.underProcessOrders.codAmount
                                      ? row.underProcessOrders.codAmount
                                          ?.toFixed(2)
                                          ?.replace(formatReplace, ",")
                                      : 0}
                                  </td>
                                  <td style={{ fontSize: "13px" }}>
                                    {row.underProcessOrders.invCharges
                                      ? row.underProcessOrders.invCharges
                                          ?.toFixed(2)
                                          ?.replace(formatReplace, ",")
                                      : 0}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "13px",
                                      textAlign: "inherit",
                                    }}
                                  >
                                    {row.total.packets?.toLocaleString()}
                                  </td>
                                  <td style={{ fontSize: "13px" }}>
                                    {row.total.codAmount
                                      ? row.total.codAmount
                                          ?.toFixed(2)
                                          ?.replace(formatReplace, ",")
                                      : 0}
                                  </td>
                                  <td style={{ fontSize: "13px" }}>
                                    {row.total.invCharges
                                      ? row.total.invCharges
                                          ?.toFixed(2)
                                          ?.replace(formatReplace, ",")
                                      : 0}
                                  </td>
                                </tr>
                              ))}

                              {grandTotal && (
                                <tr className="grand-total">
                                  <td colSpan={2}>Grand Total</td>
                                  <td style={{ textAlign: "inherit" }}>
                                    {grandTotal.deliveredOrders.packets?.toLocaleString()}
                                  </td>
                                  <td>
                                    {grandTotal.deliveredOrders.codAmount
                                      ? grandTotal.deliveredOrders.codAmount
                                          ?.toFixed(2)
                                          ?.replace(formatReplace, ",")
                                      : 0}
                                  </td>
                                  <td>
                                    {grandTotal.deliveredOrders.invCharges
                                      ? grandTotal.deliveredOrders.invCharges
                                          ?.toFixed(2)
                                          ?.replace(formatReplace, ",")
                                      : 0}
                                  </td>
                                  <td style={{ textAlign: "inherit" }}>
                                    {grandTotal.beingReturnOrders.packets?.toLocaleString()}
                                  </td>
                                  <td>
                                    {grandTotal.beingReturnOrders.codAmount
                                      ? grandTotal.beingReturnOrders.codAmount
                                          ?.toFixed(2)
                                          ?.replace(formatReplace, ",")
                                      : 0}
                                  </td>
                                  <td>
                                    {grandTotal.beingReturnOrders.invCharges
                                      ? grandTotal.beingReturnOrders.invCharges
                                          ?.toFixed(2)
                                          ?.replace(formatReplace, ",")
                                      : 0}
                                  </td>
                                  <td style={{ textAlign: "inherit" }}>
                                    {grandTotal.returnToShipperOrders.packets?.toLocaleString()}
                                  </td>
                                  <td>
                                    {grandTotal.returnToShipperOrders.codAmount
                                      ? grandTotal.returnToShipperOrders.codAmount
                                          ?.toFixed(2)
                                          ?.replace(formatReplace, ",")
                                      : 0}
                                  </td>
                                  <td>
                                    {grandTotal.returnToShipperOrders.invCharges
                                      ? grandTotal.returnToShipperOrders.invCharges
                                          ?.toFixed(2)
                                          ?.replace(formatReplace, ",")
                                      : 0}
                                  </td>
                                  <td style={{ textAlign: "inherit" }}>
                                    {grandTotal.underProcessOrders.packets?.toLocaleString()}
                                  </td>
                                  <td>
                                    {grandTotal.underProcessOrders.codAmount
                                      ? grandTotal.underProcessOrders.codAmount
                                          ?.toFixed(2)
                                          ?.replace(formatReplace, ",")
                                      : 0}
                                  </td>
                                  <td>
                                    {grandTotal.underProcessOrders.invCharges
                                      ? grandTotal.underProcessOrders.invCharges
                                          ?.toFixed(2)
                                          ?.replace(formatReplace, ",")
                                      : 0}
                                  </td>
                                  <td style={{ textAlign: "inherit" }}>
                                    {grandTotal.total.packets?.toLocaleString()}
                                  </td>
                                  <td>
                                    {grandTotal.total.codAmount
                                      ? grandTotal.total.codAmount
                                          ?.toFixed(2)
                                          ?.replace(formatReplace, ",")
                                      : 0}
                                  </td>
                                  <td>
                                    {grandTotal.total.invCharges
                                      ? grandTotal.total.invCharges
                                          ?.toFixed(2)
                                          ?.replace(formatReplace, ",")
                                      : 0}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="footer">
            COD - Leopards Template by{" "}
            <a
              style={{ textDecoration: "none", color: "#5A738E" }}
              href="https://www.leopardscourier.com/"
            >
              Leopards Courier
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default App;
